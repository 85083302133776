<template>
  <div>
    <div class="head-background">
      <span class="title">差旅政策</span>
    </div>
    <van-image width="100%" src="./policy.png" />
  </div>
</template>

<script>
export default {
  mounted() {
    window.setTitle("差旅政策");
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="less" scoped>
.title{
  color: #fff;
  display: block;
  padding: 0.8rem;
  font-size: 20px;
  text-align: center;
}
// 黑色背景图片
// .policy-cont {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: 1rem 2px;
  // box-sizing: border-box;
  // background: #191919;
// }
</style>

